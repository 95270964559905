import { spoApi } from 'dataaccess/axios.service';
import { isNullOrEmpty } from 'utils/ui.helpers';

const buildQueryParamsFromAdminFilter = (filters, prefix) => {
  let returnStr = '';
  if (filters) {
    const paramKeys = Object.keys(filters);
    if (paramKeys.length > 0) {
      for (let [index, paramKey] of paramKeys.entries()) {
        // Add '&' for all the params with index > 0 else add the prefix.
        returnStr += `${index > 0 ? '&' : prefix}${paramKey}=${encodeURIComponent(
          JSON.stringify(filters[paramKey]),
        )}`;
      }
    }
  }
  return returnStr;
};

export const getSpoStatusCount = async (token, adminFilters, soNbr) => {
  let url = `/v2/status-count`;
  if (!isNullOrEmpty(soNbr)) {
    url += `?soNbr=${soNbr}${buildQueryParamsFromAdminFilter(adminFilters, '&')}`;
  } else {
    url += buildQueryParamsFromAdminFilter(adminFilters, '?');
  }
  const resp = await spoApi.get(url, {
    headers: {
      Authorization: token,
    },
  });

  return resp?.data?.payload || {};
};

export const getApprovedOrdersCount = async (token, adminFilters) => {
  const resp = await spoApi.get(
    `/v2/approved-orders${buildQueryParamsFromAdminFilter(adminFilters, '?')}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return resp?.data?.payload || [];
};

/**
 *
 * @param {*} token - AuthToken
 * @param {*} gropuStatus - Group Status Value
 * @param {*} status - Individual SPO Status
 * @param {*} limit - Page Limit
 * @param {*} offset - Page Offset/Number
 * @param {*} filter - Filter Object
 * @param {*} order - Order By Object
 * @returns
 */
export const getSpoList = async (
  token,
  groupStatus,
  status,
  limit,
  offset,
  filter,
  order,
  adminFilters,
) => {
  let queryParams = status ? `?status=${status}` : `?groupStatus=${groupStatus}`;
  queryParams += `&limit=${limit}&offset=${offset}`;

  if (filter) {
    queryParams += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
  }

  if (order) {
    queryParams += `&orderBy=${encodeURIComponent(JSON.stringify(order))}`;
  }

  const resp = await spoApi.get(
    `/v2/orders/current${queryParams}${buildQueryParamsFromAdminFilter(adminFilters, '&')}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );
  return resp?.data?.payload || [];
};

export const getCurrentCycle = async (token) => {
  const resp = await spoApi.get(`/v2/current-cycle`, {
    headers: {
      Authorization: token,
    },
  });

  return resp?.data?.payload || {};
};

export const getSpoQuotaUsageDetails = async (token, adminFilters) => {
  const resp = await spoApi.get(
    `/v2/quota-usage${buildQueryParamsFromAdminFilter(adminFilters, '?')}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return resp?.data?.payload || {};
};

export const getCancelledSpoCount = async (token, adminFilters) => {
  const resp = await spoApi.get(
    `/v2/cancelled-orders${buildQueryParamsFromAdminFilter(adminFilters, '?')}`,
    {
      headers: {
        Authorization: token,
      },
    },
  );

  return resp?.data?.payload || [];
};

export const approveSpo = async (token, requestPayload) => {
  const resp = await spoApi.post(`/v2/approve`, requestPayload, {
    headers: {
      Authorization: token,
    },
  });

  return resp;
};

export const rejectSpo = async (token, requestPayload) => {
  const resp = await spoApi.post(`/v2/reject`, requestPayload, {
    headers: {
      Authorization: token,
    },
  });

  return resp;
};

export const getSpoDetails = async (token, orderId) => {
  const resp = await spoApi.get(`/v2/order/${orderId}`, {
    headers: {
      Authorization: token,
    },
  });
  return resp?.data?.payload || [];
};
