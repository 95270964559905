import AvatarName from 'components/AvatarName/AvatarName';
import DateDisplay from 'components/DateDisplay/DateDisplay';
import Status from 'components/Status/Status';

// Maintain all the fields needed for all the tables of the Dashboard.
export const fields = Object.freeze([
  {
    id: 'SO_NUMBER',
    columnName: 'SO No.',
    mapping: 'so_nbr',
  },
  {
    id: 'STATUS',
    columnName: 'Status',
    mapping: 'stat',
    isComponent: true,
    componentToRender: Status,
    componentProps: (cellData) => ({
      status: cellData?.stat,
    }),
  },
  {
    id: 'DENIAL_REASON',
    columnName: 'Denial Reason',
    mapping: 'sub_stat',
  },
  {
    id: 'EXTRACTED_GETSUDO',
    columnName: 'Extracted Getsudo',
    mapping: 'ord_pd',
    isComponent: true,
    componentToRender: DateDisplay,
    componentProps: (cellData) => ({
      date: cellData?.ord_pd,
      format: { year: 'numeric', month: 'short', timeZone: 'UTC' },
    }),
  },
  {
    id: 'SERIES',
    columnName: 'Series',
    mapping: 'sales_series_nm',
  },
  {
    id: 'MODEL_YEAR',
    columnName: 'Model Year',
    mapping: 'mdl_yr',
  },
  {
    id: 'MODEL_CODE',
    columnName: 'Model Code',
    mapping: 'mdl_cd',
  },
  {
    id: 'DEALER_CODE',
    columnName: 'Dealer Code',
    mapping: 'dlr_cd',
  },
  {
    id: 'CUSTOMER_NAME',
    columnName: 'Customer Name',
    mapping: 'fst_nm',
    isComponent: true,
    componentToRender: AvatarName,
    componentProps: (cellData) => ({
      name: `${cellData?.fst_nm} ${cellData?.last_nm}`,
    }),
  },
  {
    id: 'REQUESTED_DATE',
    columnName: 'Requested Date',
    mapping: 'so_dt',
    isComponent: true,
    componentToRender: DateDisplay,
    componentProps: (cellData) => ({
      date: cellData?.so_dt,
      format: { year: 'numeric', month: 'short', day: 'numeric' },
    }),
  },
  {
    id: 'STATUS_UPDATED_ON',
    columnName: 'Updated Date',
    mapping: 'stat_aud_crt_ts',
    isComponent: true,
    componentToRender: DateDisplay,
    componentProps: (cellData) => ({
      date: cellData?.stat_aud_crt_ts,
      format: { year: 'numeric', month: 'short', day: 'numeric' },
    }),
  },
]);

// Set the order of the table columns based on the table type.
export const DEFAULT_DASHBOARD_TABLE_ORDER = Object.freeze([
  {
    id: 'SO_NUMBER',
    columnName: 'SO No.',
  },
  {
    id: 'STATUS',
    columnName: 'Status',
  },
  {
    id: 'STATUS_UPDATED_ON',
    columnName: 'Updated Date',
  },
  {
    id: 'CUSTOMER_NAME',
    columnName: 'Customer Name',
  },
  {
    id: 'SERIES',
    columnName: 'Series',
  },
  {
    id: 'MODEL_YEAR',
    columnName: 'Model Year',
  },
  {
    id: 'MODEL_CODE',
    columnName: 'Model Code',
  },
  {
    id: 'DEALER_CODE',
    columnName: 'Dealer Code',
  },
  {
    id: 'REQUESTED_DATE',
    columnName: 'Requested Date',
  },
]);

export const APPROVED_HISTORY_TABLE_ORDER = Object.freeze([
  {
    id: 'SO_NUMBER',
    columnName: 'SO No.',
  },
  {
    id: 'STATUS',
    columnName: 'Status',
  },
  {
    id: 'EXTRACTED_GETSUDO',
    columnName: 'Extracted Getsudo',
  },
  {
    id: 'CUSTOMER_NAME',
    columnName: 'Customer Name',
  },
  {
    id: 'SERIES',
    columnName: 'Series',
  },
  {
    id: 'MODEL_YEAR',
    columnName: 'Model Year',
  },
  {
    id: 'MODEL_CODE',
    columnName: 'Model Code',
  },
  {
    id: 'DEALER_CODE',
    columnName: 'Dealer Code',
  },
  {
    id: 'REQUESTED_DATE',
    columnName: 'Requested Date',
  },
]);

export const CANCELLED_TABLE_ORDER = Object.freeze([
  {
    id: 'SO_NUMBER',
    columnName: 'SO No.',
  },
  {
    id: 'STATUS',
    columnName: 'Status',
  },
  {
    id: 'DENIAL_REASON',
    columnName: 'Denial Reason',
  },
  {
    id: 'CUSTOMER_NAME',
    columnName: 'Customer Name',
  },
  {
    id: 'SERIES',
    columnName: 'Series',
  },
  {
    id: 'MODEL_YEAR',
    columnName: 'Model Year',
  },
  {
    id: 'MODEL_CODE',
    columnName: 'Model Code',
  },
  {
    id: 'DEALER_CODE',
    columnName: 'Dealer Code',
  },
  {
    id: 'REQUESTED_DATE',
    columnName: 'Requested Date',
  },
]);

export const EXPIRED_TABLE_ORDER = Object.freeze([
  {
    id: 'SO_NUMBER',
    columnName: 'SO No.',
  },
  {
    id: 'STATUS',
    columnName: 'Status',
  },
  {
    id: 'CUSTOMER_NAME',
    columnName: 'Customer Name',
  },
  {
    id: 'SERIES',
    columnName: 'Series',
  },
  {
    id: 'MODEL_YEAR',
    columnName: 'Model Year',
  },
  {
    id: 'MODEL_CODE',
    columnName: 'Model Code',
  },
  {
    id: 'DEALER_CODE',
    columnName: 'Dealer Code',
  },
  {
    id: 'REQUESTED_DATE',
    columnName: 'Requested Date',
  },
]);

// Return the fields based on the table type.
export const getFields = (tableOrder) => {
  if (tableOrder) {
    const orderedFields = tableOrder?.map((to) => {
      return fields.find((f) => f.id === to.id);
    });
    return orderedFields;
  }
  return fields;
};
