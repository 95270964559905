import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { datadogRum } from '@datadog/browser-rum';
import { getEnv } from './utils/environment';
import Router from './Router';
import theme from './theme';
import PreloadProvider from 'contexts/Preload.context';
import { DatacoreProvider } from 'contexts/Datacore.context';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const forgerockUri = window.location.origin.includes('one.nso.toyota.com')
  ? 'https://ep.fram.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily'
  : 'https://ep.fram.qa.idm.toyota.com/openam/oauth2/realms/root/realms/dealerdaily';

const oidcConfig = {
  onSigninCallback: () => {
    const pathname = sessionStorage.getItem('cube-pathname') || '/';
    sessionStorage.removeItem('cube-pathname');
    window.history.replaceState(null, '', window.location.origin + pathname);
  },
  authority: forgerockUri,
  client_id: 'DSMNSO',
  redirect_uri: window.location.origin,
  scope: 'openid profile',
};

const env = getEnv();

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  datadogRum.init({
    applicationId: 'c4222b6f-ad74-4154-bccf-ac92f601bf56',
    clientToken: 'pub24d1f7e8009d8efeecffda0964e609ba',
    site: 'datadoghq.com',
    service: 'cube-special-order-ui',
    env,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}

const queryClient = new QueryClient();

root.render(
  <AuthProvider {...oidcConfig}>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <CssBaseline />
        <BrowserRouter>
          <PreloadProvider>
            <DatacoreProvider>
              <Router />
            </DatacoreProvider>
          </PreloadProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  </AuthProvider>,
);
