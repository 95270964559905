import React, { lazy, Suspense, useContext } from 'react';
import { Navigate, Routes, Route, useSearchParams } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import { useAuth } from 'react-oidc-context';
import { PreloadContext } from 'contexts/Preload.context';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import NavBar from 'components/NavBar/NavBar';
import { getBaseURL } from 'utils/environment';
import VehicleDrawer from 'views/VehicleDrawer/VehicleDrawer';
import SpoDashboardProvider from 'contexts/SpoDashboard.context';
import { useSessionStorage } from 'hooks/useStorage';
import { STORAGE_KEYS } from 'utils/enums';
import UserProfileProvider from 'contexts/UserProfile.context';

const Home = lazy(() => import('./pages/Home/Home'));
// const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));
const Historical = lazy(() => import('./pages/Historical/Historical'));
const PreStaging = lazy(() => import('./pages/PreStaging/PreStaging'));
const Staging = lazy(() => import('./pages/Staging/Staging'));
// const Config = lazy(() => import('./pages/Config/Config'));
const ManageSpo = lazy(() => import('./pages/ManageSpo/ManageSpo'));
const ErrorPage = lazy(() => import('./pages/ErrorPage/ErrorPage'));

const Router = () => {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { roles } = useContext(PreloadContext);
  const setDashboardCtxValue = useSessionStorage(STORAGE_KEYS.DASHBOARD_CONTEXT, null)[1];

  const selectedOrderNumber = searchParams.get('order');

  switch (auth.activeNavigator) {
    case 'signinSilent':
    case 'signinRedirect':
      return <div>Signing you in...</div>;
    case 'signoutRedirect':
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <LoadingSpinner fullScreen />;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (!auth.isAuthenticated) {
    const pathname = window.location.pathname;
    if (pathname !== '/') {
      sessionStorage.setItem('cube-pathname', window.location.pathname);
    }
    auth.signinRedirect();
    return null;
  }

  if (!roles.includes('CUBE_NSO_ADMIN') && window.location.pathname !== '/authorization') {
    return <Navigate to="/authorization" replace={true} />;
  }

  return (
    <Suspense fallback={<LoadingSpinner fullScreen />}>
      <NavBar
        subNavTitle="Sold Order"
        subNavItems={[
          { label: 'National Sold Order', link: `https://${getBaseURL()}` },
          { label: 'Special Order Approval', link: '/' },
          // { label: 'SPO: Dashboard', link: '/dashboard' },
          { label: 'SPO: Config', link: '/manage' },
          {
            label: 'SPO: Tables',
            subItems: [
              { label: 'SPO: Historical', link: '/historical' },
              { label: 'SPO: TOMS PreStaging', link: '/prestaging' },
              { label: 'SPO: TOMS Staging', link: '/staging' },
            ],
          },
        ]}
        handleLogout={() => {
          setDashboardCtxValue();
          auth.signoutRedirect();
        }}
      />
      <Routes>
        <Route
          path="/"
          element={
            <SpoDashboardProvider>
              <UserProfileProvider>
                <Home />
              </UserProfileProvider>
            </SpoDashboardProvider>
          }
        />
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route path="/historical" element={<Historical />} />
        <Route path="/prestaging" element={<PreStaging />} />
        <Route path="/staging" element={<Staging />} />
        <Route path="/manage" element={<ManageSpo />} />
        {/* <Route path="/config" element={<Config />} /> */}
        <Route path="/authorization" element={<ErrorPage type="authorization" />} />
        <Route path="*" element={<ErrorPage type="404" />} />
      </Routes>
      <VehicleDrawer
        open={!!selectedOrderNumber}
        spoSk={selectedOrderNumber}
        handleClose={() => {
          searchParams.delete('order');
          setSearchParams(searchParams);
        }}
      />
    </Suspense>
  );
};

export default withErrorBoundary(Router, {
  fallback: <ErrorPage type="problems" hideNavBar />,
});
