import { request } from 'graphql-request';
import {
  DEALER_DETAILS,
  MODEL_YEAR_SPECS,
  REGION_DETAILS,
  SERIES_DETAILS,
} from 'graphql/queries/datacoreReference';
import { getBaseURL } from 'utils/environment';

// Below 3 functions are used to get the reference data from DataCore to populate the dropdowns on the Add/Edit dialog.
export const getRegionData = async (token, variables) => {
  const resp = await request(`https://api.${getBaseURL()}/graphql`, REGION_DETAILS, variables, {
    Authorization: token,
  });
  return resp?.getRegionList?.regionlist;
};

export const getDealerData = async (token) => {
  const resp = await request(`https://api.${getBaseURL()}/graphql`, DEALER_DETAILS, null, {
    Authorization: token,
  });
  return resp?.getDealerList;
};

export const getSeriesData = async (token, variables) => {
  const resp = await request(`https://api.${getBaseURL()}/graphql`, SERIES_DETAILS, variables, {
    Authorization: token,
  });
  return resp?.getSeriesList?.serieslist;
};

export const getModelYearSpecs = async (token, variables) => {
  const resp = await request(`https://api.${getBaseURL()}/graphql`, MODEL_YEAR_SPECS, variables, {
    Authorization: token,
  });
  return resp?.getModelList;
};
