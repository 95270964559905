import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'react-oidc-context';
import { getDealerData, getRegionData, getSeriesData } from 'services/datacore.service';
import { produce } from 'immer';
import { getMakeFromRegion } from 'utils/ui.helpers';

const initialState = {
  region: 120, //Default Selected Region to 120 | SF
  regionData: [],
  dealerData: [],
  seriesData: [],
  modelData: [],
};
export const DatacoreContext = createContext();

export const DatacoreReducer = (state, action) => {
  switch (action.type) {
    case 'REGION_DATA':
      return {
        ...state,
        regionData: action.payload,
      };
    case 'DEALER_DATA':
      return {
        ...state,
        dealerData: action.payload,
      };
    case 'SERIES_DATA':
      return {
        ...state,
        seriesData: action.payload,
      };
    case 'REGION':
      return {
        ...state,
        region: action.payload,
      };
    default:
      return state;
  }
};

export const DatacoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(produce(DatacoreReducer), initialState);
  const { regionData, dealerData, seriesData, region } = state;
  const auth = useAuth();

  useEffect(() => {
    auth?.isAuthenticated && getDatacoreValues();
  }, [auth]);

  useEffect(() => {
    if (region && auth?.isAuthenticated) {
      const make = getMakeFromRegion(region?.toString());
      getSeriesData(auth?.user?.access_token, { filterModel: { make: { eq: make } } }).then(
        (data) => {
          data.sort((a, b) => a.series.localeCompare(b.series));
          updateSeriesData(data);
        },
      );
    }
  }, [region]);

  const updateDealerData = (value) => {
    dispatch({
      type: 'DEALER_DATA',
      payload: value,
    });
  };

  const updateSeriesData = (value) => {
    dispatch({
      type: 'SERIES_DATA',
      payload: value,
    });
  };

  const updateRegionData = (value) => {
    dispatch({
      type: 'REGION_DATA',
      payload: value,
    });
  };

  const updateRegion = (value) => {
    dispatch({
      type: 'REGION',
      payload: value,
    });
  };

  const getDatacoreValues = async () => {
    // getRegionData(auth?.user?.access_token).then((data) => setRegionData(data));
    getDealerData(auth?.user?.access_token).then((data) => updateDealerData(data));
    getSeriesData(auth?.user?.access_token, { filterModel: {} }).then((data) => {
      data.sort((a, b) => a.series.localeCompare(b.series));
      updateSeriesData(data);
    });
    const regionList = await Promise.allSettled([
      getRegionData(auth?.user?.access_token, { make: 'Toyota' }),
      getRegionData(auth?.user?.access_token, { make: 'Lexus' }),
    ]);
    const regionData = regionList?.reduce((acc, rl) => {
      if (rl.status === 'fulfilled') {
        acc = [...acc, ...rl.value];
        acc.sort((a, b) => a.region_code - b.region_code);
      }
      return acc;
    }, []);
    updateRegionData(regionData);
  };

  return (
    <DatacoreContext.Provider value={{ regionData, dealerData, seriesData, updateRegion }}>
      {children}
    </DatacoreContext.Provider>
  );
};

DatacoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
