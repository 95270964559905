import { createTheme } from '@mui/material/styles';
import typography from './typography';

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 1024,
    },
  },
  typography: {
    fontFamily: 'Toyota Type',
    h1: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 600,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    body3: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1rem',
    },
    body4: {
      fontSize: '0.875rem',
      fontWeight: 600,
      lineHeight: '1rem',
    },
    body5: {
      fontSize: '0.75rem',
      fontWeight: 600,
      lineHeight: '1rem',
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    button: {
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    hero: {
      fontSize: '3rem',
      fontWeight: 600,
    },
  },
  palette: {
    background: {
      default: '#F8F8F9',
      light: '#f1f2f4',
      dark: '#dbdcde',
      paper: '#FFFFFF',
      table: '#FCFCFD',
      surfacePaper: '#ffffff',
    },
    divider: '#D8D8D8',
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#191A1C',
      light: '#767676',
      dark: '#303132',
      30: '#58595b',
    },
    info: {
      main: '#2469FF',
      dark: '#1949B2',
    },
    success: {
      main: '#22A63E',
    },
    warning: {
      main: '#E66C02',
    },
    orange: {
      main: '#ff5d3d',
      contrastText: '#fff',
    },
    error: {
      main: '#B00716',
      e50: '#c40818',
      e30: '#eb0a1e',
      e80: '#ffa4a4',
    },
    neutral: {
      n92: '#e6e6e6',
    },
    grey: {
      main: '#767676',
      light: '#58595b',
      dark: '#191A1C',
      medium: '#c4c4c4',
    },
    green: {
      main: '#1f9738',
      light: '#99d6a6',
      dark: '#135b22',
    },
    purple: {
      main: '#431c7c',
    },
    red: {
      main: '#76050E',
    },
    action: {
      disabledBackground: '#dbdcde',
      disabled: '#909295',
    },
    data: {
      light: '#5900d9',
    },
    vehicleDamageSeverity: {
      step1: '#f68e98',
      step2: '#ef3b4b',
      step3: '#eb0a1e',
      step4: '#a70715',
      step5: '#810611',
      step6: '#63040d',
    },
    vehicleDamageTable: {
      damaged: '#F9E6E8',
      repaired: '#EDF0FF',
    },
    vehicleDamageSeverityList: {
      itemHover: '#e3e4e6',
    },
    chip: {
      currentSaleBackground: '#e9f6ec',
      currentSaleText: '#0e461a',
      creditBackground: '#ff832b',
      fleetChipBackground: '#e9f0ff',
      fleetChipText: '#0f2c6b',
      currentEvent: '#143a8c',
      expiredBackground: '#feebed',
      extractedBackground: '#22a63e',
    },
    barGraph: {
      soldOrder: '#99d6a6',
      preSold: '#1f9739',
      reserve: '#135b22',
    },
    quota: {
      current: '#E7F3F2',
      upcoming: '#FDF0E6',
      total: '#E9F0FF',
      totalHighlight: '#BBD1FF',
    },
    cycles: {
      PPR1: '#54AAAA',
      PPR2: '#A676EB',
      PPR3: '#F1C21B',
    },
    text: {
      white: '#ffffff',
      primary: '#000000',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        typography,
        html: {
          scrollBehavior: 'smooth',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          paddingLeft: '2rem',
          paddingRight: '2rem',
          [theme.breakpoints.down('tablet')]: {
            paddingLeft: '1rem',
            paddingRight: '1rem',
          },
        }),
        disableGutters: {
          padding: 0,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          paddingTop: '0.75rem',
          paddingBottom: '0.75rem',
        },
        sizeSmall: {
          paddingTop: '0.45rem',
          paddingBottom: '0.45rem',
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',
          '& .MuiTouchRipple-root .MuiTouchRipple-child': {
            borderRadius: '0.25rem',
          },
        },
      },
      defaultProps: {
        centerRipple: false,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',
          padding: '0.25rem 0.5rem',
          height: '1.5rem',
        },
        label: {
          fontSize: '0.75rem',
          lineHeight: '1rem',
          padding: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '1rem',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '6.5rem',
          zIndex: 1100,
        },
      },
    },
  },
});

export default theme;
