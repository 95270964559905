import { MAKE } from './enums';

export const isNullOrEmpty = (val) => {
  if (typeof val !== 'boolean' && (!val || (val && val.trim() === ''))) {
    return true;
  }
  return false;
};

export const deepCopy = (data) => {
  return JSON.parse(JSON.stringify(data));
};

export const createFilterModel = (filter, value, filterModel) => {
  if (filter && value) {
    filterModel.filter.and.push({ filter: { contains: value } });
  }
  return filterModel;
};

export const getMakeFromRegion = (regionCode) => {
  const toyotaRegions = [
    '110',
    '120',
    '130',
    '150',
    '160',
    '170',
    '190',
    '210',
    '220',
    '230',
    '800',
    '500',
    '600',
  ];
  const lexusRegions = ['310', '320', '330', '340', '390'];
  if (toyotaRegions.includes(regionCode)) {
    return MAKE.TOYOTA;
  } else if (lexusRegions.includes(regionCode)) {
    return MAKE.LEXUS;
  }
};
