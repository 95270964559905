import { gql } from 'graphql-request';

// export const REGION_DETAILS = gql`
//   query MyQuery {
//     NSORegionAggregate(filter: { make: Toyota }) {
//       region_code
//       region_desc
//       region_short
//     }
//   }
// `;

// export const DEALER_DETAILS = gql`
//   query MyQuery {
//     NSODealerList(input: { code: [] }) {
//       dealerAreaCode
//       dealerAreaName
//       dealerCity
//       dealerCode
//       dealerName
//       dealerState
//       dealerZipCode
//     }
//   }
// `;

// export const SERIES_DETAILS = gql`
//   query MyQuery {
//     NSOSeriesAggregate(filter: { make: Toyota }) {
//       series
//       models {
//         modelCode
//         modelDescription
//       }
//       grades
//       carTruckIndicator
//     }
//   }
// `;

// export const MODEL_YEAR_SPECS = gql`
//   query MyQuery($model_code: String, $model_year: Int) {
//     NSOModelList(input: [{ modelCode: $model_code, modelYear: $model_year }]) {
//       make
//       series
//       carTruckIndicator
//       modelYear
//       modelPhaseCode
//       modelDescription
//       modelCode
//       ppoAccessories
//       fioAccessories
//       interiorColors {
//         code
//         description
//       }
//       exteriorColors {
//         description
//         code
//       }
//       grade
//     }
//   }
// `;

export const REGION_DETAILS = gql`
  query RegionListQuery($make: String!) {
    getRegionList(filter: { make: { eq: $make } }) {
      regionlist {
        region_code
        region_desc
        region_short
      }
    }
  }
`;

export const DEALER_DETAILS = gql`
  query MyQuery {
    getDealerList(input: { code: [] }) {
      dealerCode
      dealerName
    }
  }
`;

export const SERIES_DETAILS = gql`
  query SeriesListQuery($filterModel: SeriesListFilterInput!) {
    getSeriesList(
      filter: $filterModel
      orderBy: [{ field: "series", order: ASC }]
      isDataCore: true
    ) {
      serieslist {
        series
      }
    }
  }
`;

export const MODEL_YEAR_SPECS = gql`
  query ModelListQuery($model_code: String, $model_year: Int) {
    getModelList(input: [{ modelCode: $model_code, modelYear: $model_year }]) {
      make
      series
      carTruckIndicator
      modelYear
      modelPhaseCode
      modelDescription
      modelCode
      ppoAccessories
      fioAccessories
      interiorColors {
        code
        description
      }
      exteriorColors {
        description
        code
      }
      grade
    }
  }
`;
