import React from 'react';
import PropTypes from 'prop-types';
import { withErrorBoundary } from 'react-error-boundary';
import AlertMessage from '../AlertMessage/AlertMessage';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { ClearOutlined } from '@mui/icons-material';
import ToyotaLogo from '../../assets/ToyotaLogo.svg';
import LexusLogo from '../../assets/LexusLogo.svg';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';

import { ReadyForApprovBox } from './VehicleDetailsHeader.styles';
import { STATUS_CHIPS } from 'utils/enums';
import theme from 'theme';

const VehicleDetailsHeader = ({ vehicle, closeHandler, statusChips }) => {
  const logo = vehicle.brand.toUpperCase() === 'TOYOTA' ? ToyotaLogo : LexusLogo;
  const {
    READY_FOR_APPROVAL,
    EXPIRED,
    CUSTOMER_CONFIRMED,
    EXTRACTED,
    PENDING_CUSTOMER_CONFIRMATION,
    EXTRACTION_IN_PROCESS,
    REGION_DENIED,
    SPECS_FAILED200,
    SPECS_INVALID200,
    VALIDATION_FAILED,
    CUSTOMER_REJECTED,
    CONFIGURATION_INVALID,
    QUOTA_ALLOWANCE_USED,
  } = STATUS_CHIPS;
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} mr="0.5rem" alignItems="center">
          <Box mt={1} width="2.625rem">
            <img src={logo} alt={`${vehicle.brand}-logo`} />
          </Box>
          <Stack spacing={0.5}>
            <Typography variant="h2" component="span">
              {vehicle.soldOrderNumber.toUpperCase()}
            </Typography>
            <Typography variant="body1" component="span" color="grey.dark">
              {`${vehicle.brand.toUpperCase()} ${vehicle.series} ${vehicle.modelYear}  ${
                vehicle.grade
              }`}
            </Typography>
          </Stack>
        </Stack>
        <IconButton onClick={closeHandler}>
          <ClearOutlined color="primary" fontSize="medium" />
        </IconButton>
      </Stack>
      {statusChips && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: '10px' }}
        >
          <Stack direction="row" spacing={2} mr="0.5rem" alignItems="center">
            {/* <Box mt={1} width="2.625rem">
              <img src={logo} alt={`${vehicle.brand}-logo`} />
            </Box> */}
            <Stack spacing={0.5}>
              {/* <Typography variant="h2" component="span">
                {vehicle.soldOrderNumber.toUpperCase()}
              </Typography> */}
              <Typography
                variant="caption"
                component="span"
                color="grey.dark"
                style={{ display: 'inline-flex' }}
              >
                Status:
                {
                  <ReadyForApprovBox
                    component="span"
                    sx={{
                      backgroundColor:
                        statusChips === READY_FOR_APPROVAL
                          ? `${theme.palette.chip.fleetChipBackground}`
                          : statusChips === EXPIRED ||
                            statusChips === REGION_DENIED ||
                            statusChips === SPECS_FAILED200 ||
                            statusChips === SPECS_INVALID200 ||
                            statusChips === VALIDATION_FAILED ||
                            statusChips === CUSTOMER_REJECTED ||
                            statusChips === CONFIGURATION_INVALID ||
                            statusChips === QUOTA_ALLOWANCE_USED
                          ? `${theme.palette.chip.expiredBackground}`
                          : statusChips === CUSTOMER_CONFIRMED
                          ? `${theme.palette.chip.currentSaleBackground}`
                          : statusChips === EXTRACTED
                          ? `${theme.palette.chip.extractedBackground}`
                          : statusChips === PENDING_CUSTOMER_CONFIRMATION
                          ? `${theme.palette.warning.main}`
                          : statusChips === EXTRACTION_IN_PROCESS
                          ? `${theme.palette.background.default}`
                          : ``,
                      color:
                        statusChips === READY_FOR_APPROVAL
                          ? `${theme.palette.info.main}`
                          : statusChips === EXPIRED ||
                            statusChips === REGION_DENIED ||
                            statusChips === SPECS_FAILED200 ||
                            statusChips === SPECS_INVALID200 ||
                            statusChips === VALIDATION_FAILED ||
                            statusChips === CUSTOMER_REJECTED ||
                            statusChips === CONFIGURATION_INVALID ||
                            statusChips === QUOTA_ALLOWANCE_USED
                          ? `${theme.palette.red.main}`
                          : statusChips === CUSTOMER_CONFIRMED
                          ? `${theme.palette.chip.currentSaleText}`
                          : statusChips === EXTRACTED
                          ? `${theme.palette.background.paper}`
                          : statusChips === PENDING_CUSTOMER_CONFIRMATION
                          ? `${theme.palette.background.paper}`
                          : statusChips === EXTRACTION_IN_PROCESS
                          ? `${theme.palette.secondary.main}`
                          : ``,
                    }}
                  >
                    {statusChips === READY_FOR_APPROVAL && (
                      <ArrowCircleRightOutlinedIcon
                        fontSize="small"
                        style={{
                          marginRight: '10px',
                        }}
                      />
                    )}
                    {(statusChips === EXPIRED ||
                      statusChips === REGION_DENIED ||
                      statusChips === SPECS_FAILED200 ||
                      statusChips === SPECS_INVALID200 ||
                      statusChips === VALIDATION_FAILED ||
                      statusChips === CUSTOMER_REJECTED ||
                      statusChips === CONFIGURATION_INVALID ||
                      statusChips === QUOTA_ALLOWANCE_USED) && (
                      <CloseOutlinedIcon
                        fontSize="small"
                        style={{
                          marginRight: '10px',
                        }}
                      />
                    )}
                    {(statusChips === CUSTOMER_CONFIRMED || statusChips === EXTRACTED) && (
                      <CheckOutlinedIcon
                        fontSize="small"
                        style={{
                          marginRight: '10px',
                        }}
                      />
                    )}
                    {statusChips === PENDING_CUSTOMER_CONFIRMATION && (
                      <RepeatOutlinedIcon
                        fontSize="small"
                        style={{
                          marginRight: '10px',
                        }}
                      />
                    )}
                    {statusChips === EXTRACTION_IN_PROCESS && (
                      <RepeatOutlinedIcon
                        fontSize="small"
                        style={{
                          marginRight: '10px',
                        }}
                      />
                    )}
                    {statusChips}
                  </ReadyForApprovBox>
                }
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </>
  );
};

VehicleDetailsHeader.defaultProps = {};

VehicleDetailsHeader.propTypes = {
  vehicle: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
  statusChips: PropTypes.string,
};

export default withErrorBoundary(VehicleDetailsHeader, {
  fallback: <AlertMessage />,
});
